export let navigationIsVisible = () =>
  useState('navigationIsVisible', () => false)
export let mobileNavigationIsOpen = () =>
  useState('mobileNavigationIsOpen', () => false)
export let lightboxIsVisible = () => useState('lightboxIsVisible', () => false)
export let bodyIsFixed = () => useState('bodyIsFixed', () => false)
export let colorSchemeIsInverted = () =>
  useState('colorSchemeIsInverted', () => false)
export let headerColorSchemeIsInverted = () =>
  useState('headerColorSchemeIsInverted', () => false)
