export const appHead = {"link":[{"rel":"icon","type":"image/svg+xml","href":"/img/favicons/favicon.svg"},{"rel":"apple-touch-icon","type":"image/png","href":"/img/favicons/apple-touch-icon.png"},{"rel":"icon","type":"image/png","href":"/img/favicons/favicon.png"},{"rel":"apple-touch-icon","type":"image/png","href":"/img/favicons/apple-touch-icon.png"},{"rel":"manifest","href":"/site.webmanifest"}],"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"theme-color","content":"#ffffff"},{"property":"og:type","content":"website"}],"style":[],"script":[],"noscript":[],"htmlAttrs":{"lang":"de"},"templateParams":{"separator":"–"}}

export const appPageTransition = {"name":"page","mode":"out-in"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","trailingSlash":"append"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false