
// @ts-nocheck
import locale__home_runner_work_pokolm_pokolm_frontend_locales_en_json from "../locales/en.json";
import locale__home_runner_work_pokolm_pokolm_frontend_locales_de_json from "../locales/de.json";


export const localeCodes =  [
  "en",
  "de"
]

export const localeLoaders = {
  "en": [{ key: "../locales/en.json", load: () => Promise.resolve(locale__home_runner_work_pokolm_pokolm_frontend_locales_en_json), cache: true }],
  "de": [{ key: "../locales/de.json", load: () => Promise.resolve(locale__home_runner_work_pokolm_pokolm_frontend_locales_de_json), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../config/i18n/options.ts?hash=527d8274&config=1" /* webpackChunkName: "__config_i18n_options_ts_527d8274" */),
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "i18n_config_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./config/i18n/options.ts",
  "locales": [
    {
      "code": "en",
      "iso": "en-US",
      "name": "English",
      "files": [
        "locales/en.json"
      ]
    },
    {
      "code": "de",
      "iso": "de-DE",
      "name": "German",
      "files": [
        "locales/de.json"
      ]
    }
  ],
  "defaultLocale": "de",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": true,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": "locales/",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "http://localhost:3000",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "about-pokolm": {
      "de": "/about-pokolm",
      "en": "/about-pokolm"
    },
    "career/index": {
      "de": "/karriere",
      "en": "/career"
    },
    "career/jobs/detail": {
      "de": "/karriere/stellenangebote/detail",
      "en": "/career/jobs/detail"
    },
    "career/jobs/index": {
      "de": "/karriere/stellenangebote",
      "en": "/career/jobs"
    },
    "career/training/index": {
      "de": "/karriere/ausbildung",
      "en": "/career/training"
    },
    "contact": {
      "de": "/kontakt",
      "en": "/contact"
    },
    "font-sizes-observer": {
      "de": "/font-sizes-observer",
      "en": "/font-sizes-observer"
    },
    "gtc": {
      "de": "/gtc",
      "en": "/gtc"
    },
    "imprint": {
      "de": "/imprint",
      "en": "/imprint"
    },
    "materials/[...slug]": {
      "de": "/werkstoffe/[...slug]",
      "en": "/materials/[...slug]"
    },
    "materials/index": {
      "de": "/werkstoffe",
      "en": "/materials"
    },
    "news/[...slug]": {
      "de": "/news/[...slug]",
      "en": "/news/[...slug]"
    },
    "news/detail1": {
      "de": "/news/detail1",
      "en": "/news/detail1"
    },
    "news/detail2": {
      "de": "/news/detail2",
      "en": "/news/detail2"
    },
    "news/detail3": {
      "de": "/news/detail3",
      "en": "/news/detail3"
    },
    "news/detail4": {
      "de": "/news/detail4",
      "en": "/news/detail4"
    },
    "news/index": {
      "de": "/news",
      "en": "/news"
    },
    "privacy": {
      "de": "/datenschutz",
      "en": "/privacy"
    },
    "product-line/product-group/[...slug]": {
      "de": "/product-line/product-group/[...slug]",
      "en": "/product-line/product-group/[...slug]"
    },
    "sectors/index": {
      "de": "/branchen",
      "en": "/sectors"
    },
    "suppliers": {
      "de": "/suppliers",
      "en": "/suppliers"
    },
    "debug/images": {
      "de": "/debug/images",
      "en": "/debug/images"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "iso": "en-US",
    "name": "English",
    "files": [
      {
        "path": "locales/en.json"
      }
    ]
  },
  {
    "code": "de",
    "iso": "de-DE",
    "name": "German",
    "files": [
      {
        "path": "locales/de.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = true

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
