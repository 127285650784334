import { default as about_45pokolm1Sue1J9lHdMeta } from "/home/runner/work/pokolm/pokolm/frontend/pages/about-pokolm.vue?macro=true";
import { default as indexU1ASFTp70jMeta } from "/home/runner/work/pokolm/pokolm/frontend/pages/career/index.vue?macro=true";
import { default as detailKFR9NnPd1XMeta } from "/home/runner/work/pokolm/pokolm/frontend/pages/career/jobs/detail.vue?macro=true";
import { default as indexAdNarjYAbNMeta } from "/home/runner/work/pokolm/pokolm/frontend/pages/career/jobs/index.vue?macro=true";
import { default as indexTFKOAQxORsMeta } from "/home/runner/work/pokolm/pokolm/frontend/pages/career/training/index.vue?macro=true";
import { default as contactHxfxgWD2vXMeta } from "/home/runner/work/pokolm/pokolm/frontend/pages/contact.vue?macro=true";
import { default as font_45sizes_45observeryOH0CGVWd5Meta } from "/home/runner/work/pokolm/pokolm/frontend/pages/font-sizes-observer.vue?macro=true";
import { default as gtcvslhEL4ulTMeta } from "/home/runner/work/pokolm/pokolm/frontend/pages/gtc.vue?macro=true";
import { default as imprint2jEXlyWChbMeta } from "/home/runner/work/pokolm/pokolm/frontend/pages/imprint.vue?macro=true";
import { default as index12fxV5ElU2Meta } from "/home/runner/work/pokolm/pokolm/frontend/pages/index.vue?macro=true";
import { default as _91_46_46_46slug_93gqXQyMLg7kMeta } from "/home/runner/work/pokolm/pokolm/frontend/pages/materials/[...slug].vue?macro=true";
import { default as indexmPgT8ijwPPMeta } from "/home/runner/work/pokolm/pokolm/frontend/pages/materials/index.vue?macro=true";
import { default as _91_46_46_46slug_93vZlR8TQmSEMeta } from "/home/runner/work/pokolm/pokolm/frontend/pages/news/[...slug].vue?macro=true";
import { default as detail10G92HFj4MlMeta } from "/home/runner/work/pokolm/pokolm/frontend/pages/news/detail1.vue?macro=true";
import { default as detail22b2Lz1SFUeMeta } from "/home/runner/work/pokolm/pokolm/frontend/pages/news/detail2.vue?macro=true";
import { default as detail3popqPbly3tMeta } from "/home/runner/work/pokolm/pokolm/frontend/pages/news/detail3.vue?macro=true";
import { default as detail4qBAEqjDbuQMeta } from "/home/runner/work/pokolm/pokolm/frontend/pages/news/detail4.vue?macro=true";
import { default as indexAn6uk9f0GfMeta } from "/home/runner/work/pokolm/pokolm/frontend/pages/news/index.vue?macro=true";
import { default as privacyy6Lh2OCgECMeta } from "/home/runner/work/pokolm/pokolm/frontend/pages/privacy.vue?macro=true";
import { default as _91_46_46_46slug_93E3nDCg1M3yMeta } from "/home/runner/work/pokolm/pokolm/frontend/pages/product-line/product-group/[...slug].vue?macro=true";
import { default as indexqtjvmseME2Meta } from "/home/runner/work/pokolm/pokolm/frontend/pages/sectors/index.vue?macro=true";
import { default as suppliersbOjdYI2CZ4Meta } from "/home/runner/work/pokolm/pokolm/frontend/pages/suppliers.vue?macro=true";
export default [
  {
    name: about_45pokolm1Sue1J9lHdMeta?.name ?? "about-pokolm___en",
    path: about_45pokolm1Sue1J9lHdMeta?.path ?? "/en/about-pokolm/",
    meta: about_45pokolm1Sue1J9lHdMeta || {},
    alias: about_45pokolm1Sue1J9lHdMeta?.alias || [],
    redirect: about_45pokolm1Sue1J9lHdMeta?.redirect,
    component: () => import("/home/runner/work/pokolm/pokolm/frontend/pages/about-pokolm.vue").then(m => m.default || m)
  },
  {
    name: about_45pokolm1Sue1J9lHdMeta?.name ?? "about-pokolm___de",
    path: about_45pokolm1Sue1J9lHdMeta?.path ?? "/about-pokolm/",
    meta: about_45pokolm1Sue1J9lHdMeta || {},
    alias: about_45pokolm1Sue1J9lHdMeta?.alias || [],
    redirect: about_45pokolm1Sue1J9lHdMeta?.redirect,
    component: () => import("/home/runner/work/pokolm/pokolm/frontend/pages/about-pokolm.vue").then(m => m.default || m)
  },
  {
    name: indexU1ASFTp70jMeta?.name ?? "career___en",
    path: indexU1ASFTp70jMeta?.path ?? "/en/career/",
    meta: indexU1ASFTp70jMeta || {},
    alias: indexU1ASFTp70jMeta?.alias || [],
    redirect: indexU1ASFTp70jMeta?.redirect,
    component: () => import("/home/runner/work/pokolm/pokolm/frontend/pages/career/index.vue").then(m => m.default || m)
  },
  {
    name: indexU1ASFTp70jMeta?.name ?? "career___de",
    path: indexU1ASFTp70jMeta?.path ?? "/karriere/",
    meta: indexU1ASFTp70jMeta || {},
    alias: indexU1ASFTp70jMeta?.alias || [],
    redirect: indexU1ASFTp70jMeta?.redirect,
    component: () => import("/home/runner/work/pokolm/pokolm/frontend/pages/career/index.vue").then(m => m.default || m)
  },
  {
    name: detailKFR9NnPd1XMeta?.name ?? "career-jobs-detail___en",
    path: detailKFR9NnPd1XMeta?.path ?? "/en/career/jobs/detail/",
    meta: detailKFR9NnPd1XMeta || {},
    alias: detailKFR9NnPd1XMeta?.alias || [],
    redirect: detailKFR9NnPd1XMeta?.redirect,
    component: () => import("/home/runner/work/pokolm/pokolm/frontend/pages/career/jobs/detail.vue").then(m => m.default || m)
  },
  {
    name: detailKFR9NnPd1XMeta?.name ?? "career-jobs-detail___de",
    path: detailKFR9NnPd1XMeta?.path ?? "/karriere/stellenangebote/detail/",
    meta: detailKFR9NnPd1XMeta || {},
    alias: detailKFR9NnPd1XMeta?.alias || [],
    redirect: detailKFR9NnPd1XMeta?.redirect,
    component: () => import("/home/runner/work/pokolm/pokolm/frontend/pages/career/jobs/detail.vue").then(m => m.default || m)
  },
  {
    name: indexAdNarjYAbNMeta?.name ?? "career-jobs___en",
    path: indexAdNarjYAbNMeta?.path ?? "/en/career/jobs/",
    meta: indexAdNarjYAbNMeta || {},
    alias: indexAdNarjYAbNMeta?.alias || [],
    redirect: indexAdNarjYAbNMeta?.redirect,
    component: () => import("/home/runner/work/pokolm/pokolm/frontend/pages/career/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: indexAdNarjYAbNMeta?.name ?? "career-jobs___de",
    path: indexAdNarjYAbNMeta?.path ?? "/karriere/stellenangebote/",
    meta: indexAdNarjYAbNMeta || {},
    alias: indexAdNarjYAbNMeta?.alias || [],
    redirect: indexAdNarjYAbNMeta?.redirect,
    component: () => import("/home/runner/work/pokolm/pokolm/frontend/pages/career/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: indexTFKOAQxORsMeta?.name ?? "career-training___en",
    path: indexTFKOAQxORsMeta?.path ?? "/en/career/training/",
    meta: indexTFKOAQxORsMeta || {},
    alias: indexTFKOAQxORsMeta?.alias || [],
    redirect: indexTFKOAQxORsMeta?.redirect,
    component: () => import("/home/runner/work/pokolm/pokolm/frontend/pages/career/training/index.vue").then(m => m.default || m)
  },
  {
    name: indexTFKOAQxORsMeta?.name ?? "career-training___de",
    path: indexTFKOAQxORsMeta?.path ?? "/karriere/ausbildung/",
    meta: indexTFKOAQxORsMeta || {},
    alias: indexTFKOAQxORsMeta?.alias || [],
    redirect: indexTFKOAQxORsMeta?.redirect,
    component: () => import("/home/runner/work/pokolm/pokolm/frontend/pages/career/training/index.vue").then(m => m.default || m)
  },
  {
    name: contactHxfxgWD2vXMeta?.name ?? "contact___en",
    path: contactHxfxgWD2vXMeta?.path ?? "/en/contact/",
    meta: contactHxfxgWD2vXMeta || {},
    alias: contactHxfxgWD2vXMeta?.alias || [],
    redirect: contactHxfxgWD2vXMeta?.redirect,
    component: () => import("/home/runner/work/pokolm/pokolm/frontend/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactHxfxgWD2vXMeta?.name ?? "contact___de",
    path: contactHxfxgWD2vXMeta?.path ?? "/kontakt/",
    meta: contactHxfxgWD2vXMeta || {},
    alias: contactHxfxgWD2vXMeta?.alias || [],
    redirect: contactHxfxgWD2vXMeta?.redirect,
    component: () => import("/home/runner/work/pokolm/pokolm/frontend/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: font_45sizes_45observeryOH0CGVWd5Meta?.name ?? "font-sizes-observer___en",
    path: font_45sizes_45observeryOH0CGVWd5Meta?.path ?? "/en/font-sizes-observer/",
    meta: font_45sizes_45observeryOH0CGVWd5Meta || {},
    alias: font_45sizes_45observeryOH0CGVWd5Meta?.alias || [],
    redirect: font_45sizes_45observeryOH0CGVWd5Meta?.redirect,
    component: () => import("/home/runner/work/pokolm/pokolm/frontend/pages/font-sizes-observer.vue").then(m => m.default || m)
  },
  {
    name: font_45sizes_45observeryOH0CGVWd5Meta?.name ?? "font-sizes-observer___de",
    path: font_45sizes_45observeryOH0CGVWd5Meta?.path ?? "/font-sizes-observer/",
    meta: font_45sizes_45observeryOH0CGVWd5Meta || {},
    alias: font_45sizes_45observeryOH0CGVWd5Meta?.alias || [],
    redirect: font_45sizes_45observeryOH0CGVWd5Meta?.redirect,
    component: () => import("/home/runner/work/pokolm/pokolm/frontend/pages/font-sizes-observer.vue").then(m => m.default || m)
  },
  {
    name: gtcvslhEL4ulTMeta?.name ?? "gtc___en",
    path: gtcvslhEL4ulTMeta?.path ?? "/en/gtc/",
    meta: gtcvslhEL4ulTMeta || {},
    alias: gtcvslhEL4ulTMeta?.alias || [],
    redirect: gtcvslhEL4ulTMeta?.redirect,
    component: () => import("/home/runner/work/pokolm/pokolm/frontend/pages/gtc.vue").then(m => m.default || m)
  },
  {
    name: gtcvslhEL4ulTMeta?.name ?? "gtc___de",
    path: gtcvslhEL4ulTMeta?.path ?? "/gtc/",
    meta: gtcvslhEL4ulTMeta || {},
    alias: gtcvslhEL4ulTMeta?.alias || [],
    redirect: gtcvslhEL4ulTMeta?.redirect,
    component: () => import("/home/runner/work/pokolm/pokolm/frontend/pages/gtc.vue").then(m => m.default || m)
  },
  {
    name: imprint2jEXlyWChbMeta?.name ?? "imprint___en",
    path: imprint2jEXlyWChbMeta?.path ?? "/en/imprint/",
    meta: imprint2jEXlyWChbMeta || {},
    alias: imprint2jEXlyWChbMeta?.alias || [],
    redirect: imprint2jEXlyWChbMeta?.redirect,
    component: () => import("/home/runner/work/pokolm/pokolm/frontend/pages/imprint.vue").then(m => m.default || m)
  },
  {
    name: imprint2jEXlyWChbMeta?.name ?? "imprint___de",
    path: imprint2jEXlyWChbMeta?.path ?? "/imprint/",
    meta: imprint2jEXlyWChbMeta || {},
    alias: imprint2jEXlyWChbMeta?.alias || [],
    redirect: imprint2jEXlyWChbMeta?.redirect,
    component: () => import("/home/runner/work/pokolm/pokolm/frontend/pages/imprint.vue").then(m => m.default || m)
  },
  {
    name: index12fxV5ElU2Meta?.name ?? "index___en",
    path: index12fxV5ElU2Meta?.path ?? "/en/",
    meta: index12fxV5ElU2Meta || {},
    alias: index12fxV5ElU2Meta?.alias || [],
    redirect: index12fxV5ElU2Meta?.redirect,
    component: () => import("/home/runner/work/pokolm/pokolm/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index12fxV5ElU2Meta?.name ?? "index___de",
    path: index12fxV5ElU2Meta?.path ?? "/",
    meta: index12fxV5ElU2Meta || {},
    alias: index12fxV5ElU2Meta?.alias || [],
    redirect: index12fxV5ElU2Meta?.redirect,
    component: () => import("/home/runner/work/pokolm/pokolm/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93gqXQyMLg7kMeta?.name ?? "materials-slug___en",
    path: _91_46_46_46slug_93gqXQyMLg7kMeta?.path ?? "/en/materials/:slug(.*)*",
    meta: _91_46_46_46slug_93gqXQyMLg7kMeta || {},
    alias: _91_46_46_46slug_93gqXQyMLg7kMeta?.alias || [],
    redirect: _91_46_46_46slug_93gqXQyMLg7kMeta?.redirect,
    component: () => import("/home/runner/work/pokolm/pokolm/frontend/pages/materials/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93gqXQyMLg7kMeta?.name ?? "materials-slug___de",
    path: _91_46_46_46slug_93gqXQyMLg7kMeta?.path ?? "/werkstoffe/:slug(.*)*",
    meta: _91_46_46_46slug_93gqXQyMLg7kMeta || {},
    alias: _91_46_46_46slug_93gqXQyMLg7kMeta?.alias || [],
    redirect: _91_46_46_46slug_93gqXQyMLg7kMeta?.redirect,
    component: () => import("/home/runner/work/pokolm/pokolm/frontend/pages/materials/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexmPgT8ijwPPMeta?.name ?? "materials___en",
    path: indexmPgT8ijwPPMeta?.path ?? "/en/materials/",
    meta: indexmPgT8ijwPPMeta || {},
    alias: indexmPgT8ijwPPMeta?.alias || [],
    redirect: indexmPgT8ijwPPMeta?.redirect,
    component: () => import("/home/runner/work/pokolm/pokolm/frontend/pages/materials/index.vue").then(m => m.default || m)
  },
  {
    name: indexmPgT8ijwPPMeta?.name ?? "materials___de",
    path: indexmPgT8ijwPPMeta?.path ?? "/werkstoffe/",
    meta: indexmPgT8ijwPPMeta || {},
    alias: indexmPgT8ijwPPMeta?.alias || [],
    redirect: indexmPgT8ijwPPMeta?.redirect,
    component: () => import("/home/runner/work/pokolm/pokolm/frontend/pages/materials/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93vZlR8TQmSEMeta?.name ?? "news-slug___en",
    path: _91_46_46_46slug_93vZlR8TQmSEMeta?.path ?? "/en/news/:slug(.*)*",
    meta: _91_46_46_46slug_93vZlR8TQmSEMeta || {},
    alias: _91_46_46_46slug_93vZlR8TQmSEMeta?.alias || [],
    redirect: _91_46_46_46slug_93vZlR8TQmSEMeta?.redirect,
    component: () => import("/home/runner/work/pokolm/pokolm/frontend/pages/news/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93vZlR8TQmSEMeta?.name ?? "news-slug___de",
    path: _91_46_46_46slug_93vZlR8TQmSEMeta?.path ?? "/news/:slug(.*)*",
    meta: _91_46_46_46slug_93vZlR8TQmSEMeta || {},
    alias: _91_46_46_46slug_93vZlR8TQmSEMeta?.alias || [],
    redirect: _91_46_46_46slug_93vZlR8TQmSEMeta?.redirect,
    component: () => import("/home/runner/work/pokolm/pokolm/frontend/pages/news/[...slug].vue").then(m => m.default || m)
  },
  {
    name: detail10G92HFj4MlMeta?.name ?? "news-detail1___en",
    path: detail10G92HFj4MlMeta?.path ?? "/en/news/detail1/",
    meta: detail10G92HFj4MlMeta || {},
    alias: detail10G92HFj4MlMeta?.alias || [],
    redirect: detail10G92HFj4MlMeta?.redirect,
    component: () => import("/home/runner/work/pokolm/pokolm/frontend/pages/news/detail1.vue").then(m => m.default || m)
  },
  {
    name: detail10G92HFj4MlMeta?.name ?? "news-detail1___de",
    path: detail10G92HFj4MlMeta?.path ?? "/news/detail1/",
    meta: detail10G92HFj4MlMeta || {},
    alias: detail10G92HFj4MlMeta?.alias || [],
    redirect: detail10G92HFj4MlMeta?.redirect,
    component: () => import("/home/runner/work/pokolm/pokolm/frontend/pages/news/detail1.vue").then(m => m.default || m)
  },
  {
    name: detail22b2Lz1SFUeMeta?.name ?? "news-detail2___en",
    path: detail22b2Lz1SFUeMeta?.path ?? "/en/news/detail2/",
    meta: detail22b2Lz1SFUeMeta || {},
    alias: detail22b2Lz1SFUeMeta?.alias || [],
    redirect: detail22b2Lz1SFUeMeta?.redirect,
    component: () => import("/home/runner/work/pokolm/pokolm/frontend/pages/news/detail2.vue").then(m => m.default || m)
  },
  {
    name: detail22b2Lz1SFUeMeta?.name ?? "news-detail2___de",
    path: detail22b2Lz1SFUeMeta?.path ?? "/news/detail2/",
    meta: detail22b2Lz1SFUeMeta || {},
    alias: detail22b2Lz1SFUeMeta?.alias || [],
    redirect: detail22b2Lz1SFUeMeta?.redirect,
    component: () => import("/home/runner/work/pokolm/pokolm/frontend/pages/news/detail2.vue").then(m => m.default || m)
  },
  {
    name: detail3popqPbly3tMeta?.name ?? "news-detail3___en",
    path: detail3popqPbly3tMeta?.path ?? "/en/news/detail3/",
    meta: detail3popqPbly3tMeta || {},
    alias: detail3popqPbly3tMeta?.alias || [],
    redirect: detail3popqPbly3tMeta?.redirect,
    component: () => import("/home/runner/work/pokolm/pokolm/frontend/pages/news/detail3.vue").then(m => m.default || m)
  },
  {
    name: detail3popqPbly3tMeta?.name ?? "news-detail3___de",
    path: detail3popqPbly3tMeta?.path ?? "/news/detail3/",
    meta: detail3popqPbly3tMeta || {},
    alias: detail3popqPbly3tMeta?.alias || [],
    redirect: detail3popqPbly3tMeta?.redirect,
    component: () => import("/home/runner/work/pokolm/pokolm/frontend/pages/news/detail3.vue").then(m => m.default || m)
  },
  {
    name: detail4qBAEqjDbuQMeta?.name ?? "news-detail4___en",
    path: detail4qBAEqjDbuQMeta?.path ?? "/en/news/detail4/",
    meta: detail4qBAEqjDbuQMeta || {},
    alias: detail4qBAEqjDbuQMeta?.alias || [],
    redirect: detail4qBAEqjDbuQMeta?.redirect,
    component: () => import("/home/runner/work/pokolm/pokolm/frontend/pages/news/detail4.vue").then(m => m.default || m)
  },
  {
    name: detail4qBAEqjDbuQMeta?.name ?? "news-detail4___de",
    path: detail4qBAEqjDbuQMeta?.path ?? "/news/detail4/",
    meta: detail4qBAEqjDbuQMeta || {},
    alias: detail4qBAEqjDbuQMeta?.alias || [],
    redirect: detail4qBAEqjDbuQMeta?.redirect,
    component: () => import("/home/runner/work/pokolm/pokolm/frontend/pages/news/detail4.vue").then(m => m.default || m)
  },
  {
    name: indexAn6uk9f0GfMeta?.name ?? "news___en",
    path: indexAn6uk9f0GfMeta?.path ?? "/en/news/",
    meta: indexAn6uk9f0GfMeta || {},
    alias: indexAn6uk9f0GfMeta?.alias || [],
    redirect: indexAn6uk9f0GfMeta?.redirect,
    component: () => import("/home/runner/work/pokolm/pokolm/frontend/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexAn6uk9f0GfMeta?.name ?? "news___de",
    path: indexAn6uk9f0GfMeta?.path ?? "/news/",
    meta: indexAn6uk9f0GfMeta || {},
    alias: indexAn6uk9f0GfMeta?.alias || [],
    redirect: indexAn6uk9f0GfMeta?.redirect,
    component: () => import("/home/runner/work/pokolm/pokolm/frontend/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: privacyy6Lh2OCgECMeta?.name ?? "privacy___en",
    path: privacyy6Lh2OCgECMeta?.path ?? "/en/privacy/",
    meta: privacyy6Lh2OCgECMeta || {},
    alias: privacyy6Lh2OCgECMeta?.alias || [],
    redirect: privacyy6Lh2OCgECMeta?.redirect,
    component: () => import("/home/runner/work/pokolm/pokolm/frontend/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyy6Lh2OCgECMeta?.name ?? "privacy___de",
    path: privacyy6Lh2OCgECMeta?.path ?? "/datenschutz/",
    meta: privacyy6Lh2OCgECMeta || {},
    alias: privacyy6Lh2OCgECMeta?.alias || [],
    redirect: privacyy6Lh2OCgECMeta?.redirect,
    component: () => import("/home/runner/work/pokolm/pokolm/frontend/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93E3nDCg1M3yMeta?.name ?? "product-line-product-group-slug___en",
    path: _91_46_46_46slug_93E3nDCg1M3yMeta?.path ?? "/en/product-line/product-group/:slug(.*)*",
    meta: _91_46_46_46slug_93E3nDCg1M3yMeta || {},
    alias: _91_46_46_46slug_93E3nDCg1M3yMeta?.alias || [],
    redirect: _91_46_46_46slug_93E3nDCg1M3yMeta?.redirect,
    component: () => import("/home/runner/work/pokolm/pokolm/frontend/pages/product-line/product-group/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93E3nDCg1M3yMeta?.name ?? "product-line-product-group-slug___de",
    path: _91_46_46_46slug_93E3nDCg1M3yMeta?.path ?? "/product-line/product-group/:slug(.*)*",
    meta: _91_46_46_46slug_93E3nDCg1M3yMeta || {},
    alias: _91_46_46_46slug_93E3nDCg1M3yMeta?.alias || [],
    redirect: _91_46_46_46slug_93E3nDCg1M3yMeta?.redirect,
    component: () => import("/home/runner/work/pokolm/pokolm/frontend/pages/product-line/product-group/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexqtjvmseME2Meta?.name ?? "sectors___en",
    path: indexqtjvmseME2Meta?.path ?? "/en/sectors/",
    meta: indexqtjvmseME2Meta || {},
    alias: indexqtjvmseME2Meta?.alias || [],
    redirect: indexqtjvmseME2Meta?.redirect,
    component: () => import("/home/runner/work/pokolm/pokolm/frontend/pages/sectors/index.vue").then(m => m.default || m)
  },
  {
    name: indexqtjvmseME2Meta?.name ?? "sectors___de",
    path: indexqtjvmseME2Meta?.path ?? "/branchen/",
    meta: indexqtjvmseME2Meta || {},
    alias: indexqtjvmseME2Meta?.alias || [],
    redirect: indexqtjvmseME2Meta?.redirect,
    component: () => import("/home/runner/work/pokolm/pokolm/frontend/pages/sectors/index.vue").then(m => m.default || m)
  },
  {
    name: suppliersbOjdYI2CZ4Meta?.name ?? "suppliers___en",
    path: suppliersbOjdYI2CZ4Meta?.path ?? "/en/suppliers/",
    meta: suppliersbOjdYI2CZ4Meta || {},
    alias: suppliersbOjdYI2CZ4Meta?.alias || [],
    redirect: suppliersbOjdYI2CZ4Meta?.redirect,
    component: () => import("/home/runner/work/pokolm/pokolm/frontend/pages/suppliers.vue").then(m => m.default || m)
  },
  {
    name: suppliersbOjdYI2CZ4Meta?.name ?? "suppliers___de",
    path: suppliersbOjdYI2CZ4Meta?.path ?? "/suppliers/",
    meta: suppliersbOjdYI2CZ4Meta || {},
    alias: suppliersbOjdYI2CZ4Meta?.alias || [],
    redirect: suppliersbOjdYI2CZ4Meta?.redirect,
    component: () => import("/home/runner/work/pokolm/pokolm/frontend/pages/suppliers.vue").then(m => m.default || m)
  }
]