
import * as customProviderRuntime$j1JtC2LiJ3 from '/home/runner/work/pokolm/pokolm/frontend/node_modules/@tricks-gmbh/nuxt-tools/src/layers/base/config/medialibrary-provider.ts'
import * as ipxStaticRuntime$LPAybnGOlr from '/home/runner/work/pokolm/pokolm/frontend/node_modules/@nuxt/image/dist/runtime/providers/ipxStatic'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536,
    "3xl": 1920,
    "4xl": 2560
  },
  "presets": {},
  "provider": "ipxStatic",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['medialibrary']: { provider: customProviderRuntime$j1JtC2LiJ3, defaults: undefined },
  ['ipxStatic']: { provider: ipxStaticRuntime$LPAybnGOlr, defaults: {} }
}
        