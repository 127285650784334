import { ScrollTrigger } from 'gsap/ScrollTrigger'

let timeout: any = null

export default defineNuxtPlugin((nuxtApp) => {
  const state = new WeakMap()

  nuxtApp.vueApp.directive('color-changer', {
    mounted(el, binding) {
      state.set(el, createTrigger(el, binding.value))
    },
    unmounted(el, _binding) {
      const st = state.get(el)
      st?.kill()
      state.delete(el)
    },
  })
})

function createTrigger(el: HTMLElement, value: string) {
  // console.log('createTrigger color-changer', el)

  clearTimeout(timeout)
  timeout = setTimeout(() => ScrollTrigger.refresh(), 1000)

  return ScrollTrigger.create({
    trigger: el,
    start: 'top 60%',
    // endTrigger: 'html',
    end: 'bottom top',
    // markers: true,
    onToggle: (self) => {
      const toDark = value === 'dark'
      // console.log('toggle to', value, el)
      colorSchemeIsInverted().value = self.direction === 1 ? toDark : !toDark
    },
  })
}
