<template>
  <div>
    <NuxtLayout name="default">
      <!-- !! This image ('TBD') needs also to be rendered statically, see default.vue and search for "import.meta.prerender" -->
      <NuxtImgWrapper src="/img/TBD.webp" alt="" />
    </NuxtLayout>
  </div>
</template>

<script lang="ts" setup>
import type { NuxtError } from 'nuxt/app'

const props = defineProps<{
  error: NuxtError
}>()
if (props.error) {
  console.error(props.error)
}

useHead({
  title: 'Fehler 404',
  meta: [{ name: 'robots', content: 'noindex' }],
})
</script>
