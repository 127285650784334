import { ScrollTrigger } from 'gsap/ScrollTrigger'

let timeout: any = null

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('visible-add-class', {
    created(el, binding) {
      // @todo: correct process import?
      // if (process.browser) {
      // if (binding.value) {
      //   // el.classList.add(...binding.value.split(' '))
      //   el.classList.add('becomes-visible')
      // }

      el.classList.add('becomes-visible')

      createTrigger(el)
    },
    // },
  })
})

function createTrigger(el: HTMLElement) {
  el.classList.add('out')

  ScrollTrigger.create({
    trigger: el,
    // once: true,
    // markers: true,
    onEnter: (_self) => {
      setTimeout(() => {
        el.classList.add('in')
      }, 0)
    },
    onEnterBack: (_self) => {
      setTimeout(() => {
        el.classList.add('in')
      }, 0)
    },
    onLeave: (_self) => {
      setTimeout(() => {
        el.classList.remove('in')
        el.classList.add('out')
      }, 0)
    },
    onLeaveBack: (_self) => {
      setTimeout(() => {
        el.classList.remove('in')
        el.classList.add('out')
      }, 0)
    },
  })

  clearTimeout(timeout)
  timeout = setTimeout(() => ScrollTrigger.refresh(), 1000)
}
