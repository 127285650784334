
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "31ea7fbd-9503-4721-8fa7-494a4c0a6e39"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/home/runner/work/pokolm/pokolm/frontend/app.config.ts"
import cfg1 from "/home/runner/work/pokolm/pokolm/frontend/node_modules/@tricks-gmbh/nuxt-tools/src/layers/base/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, cfg1, inlineConfig)
