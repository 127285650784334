const organizationName = 'Pokolm Frästechnik'
const logoUrl = `/pokolm-logo.png`

export default defineAppConfig({
  meta: {
    name: organizationName,
    ogImage: '/img/favicons/pokolm-teaser.webp',
  },
  site: {
    name: organizationName,
  },
  schemaOrg: {
    // TODO:
    organization: {
      // use https://schema.org/LocalBusiness respectively a subtype https://schema.org/LocalBusiness#subtypes when applicable
      // '@type': 'LocalBusiness',

      description:
        'Pokolm ist Hersteller hochwertiger Fräswerkzeuge und Aufnahmesysteme. Zudem bietet die Firma Frässtrategierberatung für komplexe 2,5 und 3D-Geometrien.',

      name: organizationName,

      logo: logoUrl,
      image: logoUrl,

      address: {
        '@type': 'PostalAddress',
        streetAddress: 'Meisenstraße 96',
        addressLocality: 'Bielefeld',
        addressRegion: 'NRW',
        postalCode: '33607',
        addressCountry: 'Germany',
      },
      email: 'contact@PlasmidFactory.com',
      telephone: '+49 521 2997 350',
    },
  },
})
